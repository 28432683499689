import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Block from "../ui/Block"
import styled from "styled-components"

const Container = styled.div`
  margin-bottom: 3rem;
`

const BlogTitle = styled.h1`
  position: absolute;
  width: 100%;
  font-family: Pacifico;
  text-align: center;
  z-index: 1;
  color: #fff;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

  top: calc(50% - 1rem);

  @media (min-width: 400px) and (min-height: 500px) {
    font-size: 2.5rem;
    top: calc(50% - 1.25rem);
  }

  @media (min-width: 900px) and (min-height: 500px) {
    font-size: 3rem;
    top: calc(50% - 1.5rem);
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;

  &:hover {
    color: inherit;
  }
`

export default ({ picture, maxHeight, showBlogTitle }) => (
  <Block fullWidth>
    <Container>
      {showBlogTitle && (
        <BlogTitle>
          <StyledLink to="/">Célia+Mika.</StyledLink>
        </BlogTitle>
      )}
      <Img
        fluid={picture.childImageSharp.fluid}
        alt={picture.name}
        loading="eager"
        className="mainPicture"
        style={{
          height: "45vw",
          minHeight: "84px",
          maxHeight: maxHeight ? maxHeight : "66vh",
        }}
      />
    </Container>
  </Block>
)
