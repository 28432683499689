import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./Header"
import Footer from "./Footer"
import MainContainer from "./MainContainer"
import { Helmet } from "react-helmet"
import MainPicture from "./MainPicture"

export default ({ wide, picture, children }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativePath: { eq: "assets/defaultbg.png" } }
        limit: 1
      ) {
        nodes {
          base
          childImageSharp {
            fluid(
              maxWidth: 1280
              srcSetBreakpoints: [400, 960]
              maxHeight: 570
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet titleTemplate="%s - Célia+Mika" defaultTitle="Célia+Mika">
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header noLogoWhenTransparent={!picture} />
      <MainContainer wide={wide}>
        {picture && <MainPicture picture={picture} />}
        {!picture && (
          <MainPicture
            picture={data.allFile.nodes[0]}
            maxHeight="33vh"
            showBlogTitle
          />
        )}
        {children}
      </MainContainer>
      <Footer />
    </>
  )
}
