import React from "react"
import styled from "styled-components"

const Block = styled.section`
  ${({ moreSpacing }) =>
    moreSpacing
      ? "margin-top: 3rem; margin-bottom: 3rem;"
      : "margin-bottom: 1.666rem;"}

  /*
  &.fullWidth {
    margin-left: -10px;
    margin-right: -10px;
  }

  .narrowMainContainer &.fullWidth {
    @media (min-width: 700px) {
      margin-left: calc(-50vw + 340px);
      margin-right: calc(-50vw + 340px);
    }
  }

  .wideMainContainer &.fullWidth {
    @media (min-width: 900px) {
      margin-left: calc(-50vw + 440px);
      margin-right: calc(-50vw + 440px);
    }
  }
  */

  &.fullWidth {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  .narrowMainContainer &.wide {
    @media (min-width: 712px) and (max-width: 911px) {
      margin-left: calc(-50vw + 356px);
      margin-right: calc(-50vw + 356px);
    }

    @media (min-width: 912px) {
      margin-left: -100px;
      margin-right: -100px;
    }
  }

  .wideMainContainer &.wide {
    @media (min-width: 900px) and (max-width: 1099px) {
      margin-left: calc(-50vw + 450px);
      margin-right: calc(-50vw + 450px);
    }

    @media (min-width: 1100px) {
      margin-left: -100px;
      margin-right: -100px;
    }
  }
`

export default ({ children, fullWidth, wide, moreSpacing }) => (
  <Block
    className={fullWidth ? "fullWidth" : wide ? "wide" : ""}
    moreSpacing={moreSpacing}
  >
    {children}
  </Block>
)
