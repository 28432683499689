import React from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"
import kebabCase from "lodash/kebabCase"

const Menu = styled.nav`
  order: 4;
  display: flex;
  background-color: #fff;
  padding: 15px 30px 30px;
  width: 100%;
  font-size: 0.75rem;
`

const MenuGroup = styled.div`
  flex-grow: 1;
  margin-right: 30px;

  @media (min-width: 1000px) {
    max-width: 15%;
  }

  & h4 {
    margin-bottom: 1rem;
  }
  & ul {
    list-style-type: none;
    margin-left: 0;
  }
  & ul,
  & li {
    margin-bottom: 0.25rem;
  }
`

const MenuAbout = styled(MenuGroup)`
  display: none;

  @media (min-width: 1000px) {
    display: block;
    max-width: 33%;
    flex-grow: 2;
    margin-right: 60px;
  }
`

const MenuList = styled(MenuGroup)`
  display: none;

  @media (min-width: 800px) {
    display: block;
  }
`

const Stats = styled.div`
  line-height: 1.5;
  padding: 16px;
  margin: 18px 0 1.5rem -16px;
  background-color: #f0f0f0;
  border-radius: 8px;

  & h3 {
    margin-top: -38px;
    font-family: Pacifico;
    margin-bottom: 0.6rem;
  }

  & p {
    margin: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`

const FamilyFriends = styled.div`
  & h4 {
    margin-bottom: 0.5rem;
  }

  & p {
    margin-bottom: 0.5rem;
  }
`

const BrowseArticles = styled.div`
  margin-top: 1.5rem;

  @media (min-width: 800px) {
    display: none;
  }
`

export default () => {
  const menuItems = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          familyWebsiteUrl
        }
      }
      articles: allMdx {
        totalCount
      }
      oldestArticle: allMdx(sort: { fields: frontmatter___date }, limit: 1) {
        nodes {
          id
          frontmatter {
            date(formatString: "D MMMM YYYY", locale: "fr-FR")
          }
        }
      }
      newestArticle: allMdx(
        sort: { fields: frontmatter___date, order: DESC }
        limit: 1
      ) {
        nodes {
          frontmatter {
            date(formatString: "D MMMM YYYY", locale: "fr-FR")
          }
        }
      }
      places: allMdx(limit: 2000) {
        group(field: frontmatter___place) {
          totalCount
        }
      }
      countries: allMdx(limit: 2000) {
        group(field: frontmatter___country) {
          fieldValue
          totalCount
        }
      }
      tags: allMdx(limit: 2000) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
      years: allMdx(limit: 2000) {
        group(field: fields___year) {
          fieldValue
        }
      }
    }
  `)

  const sortedCountries = menuItems.countries.group
    .sort((a, b) => b.totalCount - a.totalCount)
    .slice(0, 10)

  const sortedTags = menuItems.tags.group
    .sort((a, b) => b.totalCount - a.totalCount)
    .slice(0, 10)

  const sortedYears = menuItems.years.group
    .sort((a, b) => b.fieldValue - a.fieldValue)
    .slice(0, 10)

  const showPrivate = process.env.SHOW_PRIVATE === "1"

  return (
    <Menu>
      <MenuAbout>
        <Stats>
          <h3>le blog de Célia+Mika.</h3>
          <p>
            <strong>{menuItems.articles.totalCount}</strong> articles publiés
            entre le{" "}
            <strong>{menuItems.oldestArticle.nodes[0].frontmatter.date}</strong>{" "}
            et le{" "}
            <strong>{menuItems.newestArticle.nodes[0].frontmatter.date}</strong>
            , couvrant <strong>{menuItems.places.group.length}</strong> lieux et{" "}
            <strong>{menuItems.countries.group.length}</strong> pays.
          </p>
        </Stats>

        {!showPrivate && (
          <FamilyFriends>
            <h4>Famille et amis</h4>
            <p>
              De nombreux articles sont accessibles uniquement à nos proches.{" "}
              Contactez-nous pour y accéder !
            </p>
            <ul>
              <li>
                <a href={menuItems.site.siteMetadata.familyWebsiteUrl}>
                  Accéder aux articles
                </a>
              </li>
              <li>
                <Link to="/register">Demander l'accès</Link>
              </li>
            </ul>
          </FamilyFriends>
        )}
        {showPrivate && (
          <FamilyFriends>
            <h4>Recevoir les nouveautés</h4>
            <p>
              Inscrivez-vous pour recevoir les nouveautés du blog par
              e-mail&nbsp;!
            </p>
            <ul>
              <li>
                <Link to="/subscribe">S'inscrire</Link>
              </li>
            </ul>
          </FamilyFriends>
        )}
      </MenuAbout>
      <MenuGroup>
        <h4>Menu principal</h4>
        <ul>
          <li>
            <Link to="/">Page d'accueil</Link>
          </li>
          {!showPrivate && (
            <>
              <li>
                <a href={menuItems.site.siteMetadata.familyWebsiteUrl}>
                  Accès famille+amis
                </a>
              </li>
              <li>
                <Link to="/register">Demander l'accès famille+amis</Link>
              </li>
            </>
          )}
          {showPrivate && (
            <li>
              <Link to="/subscribe">Recevoir les nouveautés par email</Link>
            </li>
          )}
        </ul>

        <BrowseArticles>
          <h4>Parcourir les articles</h4>
          <ul>
            <li>
              <Link to={`/countries`}>Tous les pays</Link>
            </li>
            <li>
              <Link to={`/tags`}>Toutes les catégories</Link>
            </li>
            <li>
              <Link to={`/years`}>Toutes les années</Link>
            </li>
          </ul>
        </BrowseArticles>
      </MenuGroup>
      <MenuList>
        <h4>Articles par pays</h4>
        <ul>
          {sortedCountries.map(country => (
            <li key={country.fieldValue}>
              <Link to={`/countries/${kebabCase(country.fieldValue)}`}>
                {country.fieldValue}
              </Link>
            </li>
          ))}
          <li>
            <Link to={`/countries`}>Tous les pays</Link>
          </li>
        </ul>
      </MenuList>
      <MenuList>
        <h4>Articles par catégorie</h4>
        <ul>
          {sortedTags.map(tag => (
            <li key={tag.fieldValue}>
              <Link to={`/tags/${kebabCase(tag.fieldValue)}`}>
                {tag.fieldValue}
              </Link>
            </li>
          ))}
          <li>
            <Link to={`/tags`}>Toutes les catégories</Link>
          </li>
        </ul>
      </MenuList>
      <MenuList>
        <h4>Articles par année</h4>
        <ul>
          {sortedYears.map(year => (
            <li key={year.fieldValue}>
              <Link to={`/years/${kebabCase(year.fieldValue)}`}>
                {year.fieldValue}
              </Link>
            </li>
          ))}
          <li>
            <Link to={`/years`}>Toutes les années</Link>
          </li>
        </ul>
      </MenuList>
    </Menu>
  )
}
