import React from "react"
import styled from "styled-components"

const MainContainer = styled.main`
  margin: 0 auto;
  width: calc(100% - 20px);
  max-width: ${props => (props.wide ? "880px" : "680px")};
  min-height: 90vh;
`

export default ({ children, wide }) => (
  <MainContainer
    wide={wide}
    className={wide ? "wideMainContainer" : "narrowMainContainer "}
  >
    {children}
  </MainContainer>
)
