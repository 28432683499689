import React from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"
import Menu from "./Menu"

const MenuOverlay = styled.nav`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
`

const Background = styled.nav`
  position: fixed;
  z-index: 6;
  height: ${props => (props.menuOpened ? "200px" : "84px")};
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${props => (props.transparent ? "none" : "#fff")};
  ${props =>
    props.transparent ? "" : "box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);"}
`

const TopRowContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h1`
  visibility: ${props =>
    props.noLogoWhenTransparent && props.transparent ? "hidden" : "visible"};
  order: 1;
  width: 250px;
  margin: 0;
  text-align: center;
  font-family: Pacifico;

  font-size: 24px;

  @media (min-width: 600px) {
    font-size: 40px;
  }

  color: ${props => (props.transparent ? "#fff" : "#000")};
  ${props =>
    props.transparent ? "text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);" : ""}
`

const MenuItem = styled.button`
  padding: 0 27px;
  height: 84px;
  margin: 0;
  width: 300px;
  text-transform: uppercase;
  line-height: 1.3;
  border: 0;
  background: none;
  display: flex;
  align-items: center;
  outline: unset;
  cursor: pointer;
`

const Icon = styled.i`
  display: inline-block;
`

const Text = styled.span`
  display: none;
  @media (min-width: 800px) {
    display: ${props => (props.transparent ? "none" : "inline-block")};
  }

  padding: 0 10px 5px;
`

const MenuButton = styled(MenuItem)`
  order: 0;
  text-align: left;
  flex-flow: row nowrap;
`

const FamilyButton = styled(MenuItem)`
  order: 2;
  text-align: right;
  flex-flow: row-reverse nowrap;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
`

const FamilyButtonPlaceholder = styled.div`
  order: 2;
  width: 300px;
`

const LogoLink = styled(Link)`
  text-decoration: none;

  &:hover {
    color: inherit;
  }
`

const showPrivate = process.env.SHOW_PRIVATE === "1"

export default ({ noLogoWhenTransparent }) => {
  // Manage style of header depending of scroll position
  const [
    hasScrolledPastMainPicture,
    setHasScrolledPastMainPicture,
  ] = React.useState(false)

  const [menuOpened, setMenuOpened] = React.useState(false)

  React.useLayoutEffect(() => {
    const handleScroll = e => {
      const mainPictureElement = document.getElementsByClassName(
        "mainPicture"
      )[0]
      if (mainPictureElement) {
        setHasScrolledPastMainPicture(
          window.scrollY > mainPictureElement.offsetHeight - 84
        )
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const familyWebsiteUrl = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          familyWebsiteUrl
        }
      }
    }
  `)

  return (
    <>
      {menuOpened && <MenuOverlay onClick={() => setMenuOpened(false)} />}
      <Background
        transparent={!hasScrolledPastMainPicture && !menuOpened}
        menuOpened={menuOpened}
      >
        <TopRowContainer>
          <Title
            transparent={!hasScrolledPastMainPicture && !menuOpened}
            noLogoWhenTransparent={noLogoWhenTransparent}
          >
            <LogoLink to="/">Célia+Mika.</LogoLink>
          </Title>
          <MenuButton onClick={() => setMenuOpened(!menuOpened)}>
            <Icon>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 8C3.45 8 3 7.55 3 7C3 6.45 3.45 6 4 6H20C20.55 6 21 6.45 21 7C21 7.55 20.55 8 20 8H4ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM4 18H20C20.55 18 21 17.55 21 17C21 16.45 20.55 16 20 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18Z"
                  fill={
                    !hasScrolledPastMainPicture && !menuOpened
                      ? "white"
                      : "black"
                  }
                />
              </svg>
            </Icon>
            <Text transparent={!hasScrolledPastMainPicture && !menuOpened}>
              Menu
            </Text>
          </MenuButton>

          {!showPrivate && (
            <FamilyButton
              as="a"
              href={familyWebsiteUrl.site.siteMetadata.familyWebsiteUrl}
            >
              <Icon>
                <svg width="32" height="24" viewBox="0 0 32 24" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 9H16V7C16 4.24 13.76 2 11 2C8.24 2 6 4.24 6 7V9H5C3.9 9 3 9.9 3 11V21C3 22.1 3.9 23 5 23H17C18.1 23 19 22.1 19 21V11C19 9.9 18.1 9 17 9ZM11 18C9.9 18 9 17.1 9 16C9 14.9 9.9 14 11 14C12.1 14 13 14.9 13 16C13 17.1 12.1 18 11 18ZM8 7V9H14V7C14 5.34 12.66 4 11 4C9.34 4 8 5.34 8 7Z"
                    fill={
                      !hasScrolledPastMainPicture && !menuOpened
                        ? "white"
                        : "black"
                    }
                  />
                  <path
                    d="M24.8475 10.8526L25.5 11.5L26.1525 10.8575L26.1703 10.8399C28.4778 8.5544 30 7.04676 30 5.19755C30 3.68692 28.911 2.5 27.525 2.5C26.742 2.5 25.9905 2.89728 25.5 3.52507C25.0095 2.89728 24.258 2.5 23.475 2.5C22.089 2.5 21 3.68692 21 5.19755C21 7.04998 22.5275 8.55965 24.8418 10.847L24.8475 10.8526Z"
                    fill={
                      !hasScrolledPastMainPicture && !menuOpened
                        ? "white"
                        : "black"
                    }
                  />
                </svg>
              </Icon>
              <Text transparent={!hasScrolledPastMainPicture && !menuOpened}>
                Accès
                <br />
                Famille + Amis
              </Text>{" "}
            </FamilyButton>
          )}
          {showPrivate && <FamilyButtonPlaceholder />}
        </TopRowContainer>
        {menuOpened && <Menu />}
      </Background>
    </>
  )
}
