import React from "react"
import styled from "styled-components"

const Footer = styled.footer`
  width: 100%;
  margin-top: 2rem;
`
//  background-color: #000;
// color: #fff;

const Inner = styled.div`
  margin: 0 auto;
  max-width: 680px;
  padding: 1rem 0;
  text-align: center;
`

const Title = styled.span`
  font-family: Pacifico;
`

const Copyright = styled.span`
  display: inline-block;
  font-size: 0.75rem;
  padding-left: 0.7ch;
`

export default () => {
  const year = new Date().getFullYear()

  return (
    <Footer>
      <Inner>
        <Title>le blog de Célia et Mika.</Title>
        <Copyright>— Copyright 1984 - {year}</Copyright>
      </Inner>
    </Footer>
  )
}
